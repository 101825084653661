import React from 'react';
import './App.css';
import { AppUI } from "./AppUI";
import { ProviderApp } from '../Context/ProviderApp';

function App() {
  return (
    <ProviderApp>
      <AppUI/>
    </ProviderApp>
  );
}

export default App;
