import React from "react";
import "./Speciality.css";
import { AppContext } from "../../Context/ProviderApp";
import fondoSpeciality from "../../Assets/Images/Especialidades.jpg";
import hoja from "../../Assets/Images/hojalados.png";
import flecha from "../../Assets/Icons/Team/flecha-hacia-abajo-para-navegar.png";

function Speciality() {
    const {openMenu, specialities, seleccion} = React.useContext(AppContext);

    const imagenBackground = {
        backgroundImage: `url(${fondoSpeciality})` ,
    };

    return (
        <section className={`Speciality-container  ${openMenu && "open-menu"}`}>
            <div className={`Welcome-image-container  ${openMenu && "open-menu"}`}>
                <article className="Welcome-image" style={imagenBackground}></article>
            </div>
            <article className="Speciality-welcome">
                <div className="Welcome-text">
                    <h2>Tu Solución Confiable</h2>
                    <p>En Fixcenter, estamos comprometidos a proporcionar soluciones excepcionales en reparación y mantenimiento de dispositivos móviles. Como expertos en el campo, nuestro objetivo es restaurar la funcionalidad y el rendimiento óptimo de tus dispositivos. Algunos de nuestros servicios incluyen:</p>
                    <img src={hoja} alt="hoja" className="Welcome-image-text" />
                </div>
                <div className="Background-black">
                    <ul className="Speciality-options">
                        {specialities.map(option => (
                            <div className="Option-container" key={option.proceso}>
                                <div className="Option-title-container" onClick={() => seleccion(option.proceso)}>
                                    <h2>{option.proceso}</h2>
                                    <img className={`${option.selected && "--select"}`} src={flecha} alt="flecha" />
                                </div>
                                {option.selected && (
                                    <div>
                                        <div className="Option-text">
                                            {option.texto.map(parrafo => (
                                                <p key={parrafo.parrafo}>{parrafo.texto}</p>
                                            ))}
                                        </div>
                                        <div className="Option-videos-container">
                                            {option.videos.map(video => (
                                                <img key={video.key} src={video.sr} alt={video.key} />
                                            ))}
                                        </div>
                                    </div>
                               )}
                            </div>
                        ))}
                    </ul>
                </div>
                <div  className="Text-end">
                    <p>Nuestro equipo altamente capacitado y nuestras técnicas avanzadas nos permiten abordar una amplia gama de problemas con eficiencia y precisión. En Fixcenter, valoramos tu satisfacción y la salud de tus dispositivos.</p>
                </div>
                <div className="End">
                    <p>¡Contáctanos para resolver tus necesidades de reparación y mantenimiento móvil!</p>
                </div>
            </article>
        </section>
    );
}

export {Speciality};