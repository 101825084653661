import React from "react";
import "./Footer.css";
import { AppContext } from "../../Context/ProviderApp";
import { ReactComponent as FooterSVG } from "../../Assets/Svgs/Iphone_Monochromatic.svg";

function Footer() {
    const {itemsFooter} = React.useContext(AppContext);
    
    return(
        <footer>
            <section className="Footer-svg-container">
                <FooterSVG className="Footer-svg"/>
            </section>
            <section className="Footer-text">
                <p>¡Soporte experto para tus dispositivos móviles! Potencia, rapidez y soluciones a tu alcance. ¡Contáctanos y recupera el máximo rendimiento ahora!</p>
                <h2>Fixcenter</h2>
            </section>
            <ul className="Footer-list">
                {itemsFooter.map(item => (
                    <li key={item.text} className="Footer-items">
                        <a href={item.link}>
                            <img src={item.sr} alt={item.text} className="Item-img"/>
                        </a>
                    </li>
                ))}
            </ul>
            <section className="Copyright">
                <span>Titulares Fixcenter. Luis Torres - Gerardo David Florez</span>
                <span>© Copyright Fixcenter SAS 2023. Desarrollado por Samuel J.C.</span>
            </section>
        </footer>
    );
}

export {Footer};