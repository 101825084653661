import React from "react";
import "./Button.css";
import {ReactComponent as ChatIcon} from "../../Assets/Svgs/HablaConNosotros.svg";

function ComoAyudarButton() {
    const fixCenterChat = "https://wa.link/h3ab4h";
    return(
        <>
            <div className="button-container">
                <a href={fixCenterChat} target="_blank" rel="noopener noreferrer">
                    <ChatIcon className="button-icon"/>
                    <span className="button-text">¿Necesitas ayuda? ¡Estoy aquí para asistirte!</span>
                </a>
            </div>
        </>
    );
}

export {ComoAyudarButton};