import React from "react";
import './Home.css';
import fondoHome from '../../Assets/Images/HomePruebaUno.jpg';
import desplazamiento from '../../Assets/Icons/Home/FlechaDesplazamiento.png';
import { AppContext } from "../../Context/ProviderApp";
import { ReactComponent as WelcomeSVG } from "../../Assets/Svgs/WelcomeHome.svg";
import somos from "../../Assets/Videos/QuienesSomos.mp4";

function Home() {
    const {openMenu, boolOpuesto, setMoveWelcome, moveWelcome} = React.useContext(AppContext);
    const welcomeDown = () => boolOpuesto(setMoveWelcome);

    const imagenBackground = {
        backgroundImage: `url(${fondoHome})` ,
    };

    return (
        <>
            <div className={`Image-container ${openMenu && "menu-open"}`} style={imagenBackground}></div>
            <section className="Welcome">
                <article className="Welcome-text-container" id="WelcomeSVG">
                    <div className={`Container-title ${openMenu && "menu-open"}`}>
                        <h1>Servicio técnico especializado en dispositivos móviles</h1>
                        <div className={`Container-welcome ${((openMenu && !moveWelcome) && "menu-open") || ((openMenu && moveWelcome) && "--active") }`}>
                            <p>¡Bienvenido a nuestra app de servicio técnico móvil, donde reparamos tus dispositivos con el poder de la tecnología! ¡Reparaciones rápidas, soluciones brillantes y un mundo de    posibilidades en tus manos!</p>
                        </div>
                    </div>
                    <div className={`Desplazador-container ${openMenu && "menu-open"}`}>
                        {!moveWelcome && (
                            <a href="#WelcomeSVG" onClick={welcomeDown}>
                                <img src={desplazamiento} alt="desplazamiento"/>
                            </a>
                        )}
                    </div>
                </article>
                <span className={`Icon-welcome-container ${((openMenu && !moveWelcome) && "menu-open") || ((openMenu && moveWelcome) && "--active")}`}>
                    <WelcomeSVG className="Welcome-svg"/>
                </span>
            </section>
            <section className="Whoarewe">
                <article className="Whoarewe-text-container">
                    <h2>Quienes somos</h2>
                    <p>Somos un equipo de expertos apasionados por la tecnología, dedicados a solucionar cualquier problema que puedas tener con tus dispositivos.</p>
                </article>
                <div className="Whoarewe-video-container">
                    <video controls autoPlay>
                        <source src={somos} type="video/mp4"/>
                    </video>
                </div>
            </section>
            <section className="Location">
                <article className="Location-text-container">
                    <h2>Donde encontrarnos</h2>
                    <p>¡Ven a nuestro local de reparación de dispositivos móviles y descubre cómo llegar fácilmente! Estamos ubicados en el centro de Medellín con Calle 53 # 49 - 108 (piso 2), cerca de la estación Parque Berrio del metro. ¡Te esperamos para solucionar cualquier inconveniente con tus dispositivos!</p>
                </article>
            </section>
        </>
    );
};

export {Home};