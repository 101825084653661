import React from "react";
import './NavBar.css'
import { AppContext } from "../../Context/ProviderApp";

function NavBar({children}) {
    const {openMenu} = React.useContext(AppContext);
    return (
        <nav className={`${!openMenu && 'Nav-none'} Navbar-container`}>
            <ul className={`Navbar-container-list ${!openMenu && 'Nav-none'}`}>
                {children}
            </ul>
            <div className={`Navbar-container-img ${!openMenu && 'Nav-none'}`}>
                <span className={`Navbar-primaryText ${!openMenu && 'Nav-none'}`}>La mejor opción</span>
                <span className={`Navbar-secondText ${!openMenu && 'Nav-none'}`}>de tu móvil</span>
            </div>
        </nav>
    );
};

export {NavBar};