import React from 'react';
import "./Team.css";
import { AppContext } from "../../Context/ProviderApp";
import fondoTeam from "../../Assets/Images/EquipoFixcenter/equipoFixcenter.jpg";
import Flecha from "../../Assets/Icons/Team/flecha-hacia-abajo-para-navegar.png";

function Team() {
    const {openMenu, pasarCard, atrasarCard, cardList, num} = React.useContext(AppContext);
    const cardRight = () => pasarCard();
    const cardLeft = () => atrasarCard();

    const imagenBackground = {
        backgroundImage: `url(${fondoTeam})` ,
    };

    return (
        <section className={`SubmitTeam-container`}>
            <div className={`Team  ${openMenu && "open-menu"}`}>
                <article className="Team-image" style={imagenBackground}></article>
            </div>
            <article className="Team-text-container">
                <p>Quiero dar la bienvenida a todos al apasionado y talentoso <span>equipo técnico de Fixcenter</span>. En este equipo, encontrarás una variedad de expertos en diferentes áreas, desde expertos en venta de repuestos hasta especialistas en microsoldadura. Cada uno de nosotros comparte una profunda pasión por la tecnología y la determinación de llevarla a nuevas alturas.</p>
            </article>
            <div className="Team-slider-container">
                <article className="Team-text-container">
                    <p>Este equipo no solo es diverso en experiencia, sino también en perspectivas, lo que nos permite abordar problemas de manera creativa y encontrar soluciones únicas. Creemos en la colaboración, la innovación y el <span>compromiso con la excelencia</span>. Aquí, no solo resolvemos desafíos técnicos, sino que también creamos experiencias excepcionales para nuestros usuarios.</p>
                </article>
                <div className="Team-slider">
                    <div className={`Card-container ${
                        (num === 0 && "positionOne") ||
                        (num === 1 && "positionTwo") ||
                        (num === 2 && "positionThree") ||
                        (num === 3 && "positionFour") ||
                        (num === 4 && "positionFive") ||
                        (num === 5 && "positionSix") ||
                        (num === 6 && "positionSeven") ||
                        (num === 7 && "positionEigth")
                    }`}>
                        {cardList.map(card => (
                            <div className={`Card ${card.key===num+1 && "--selected"}`} key={card.key}>
                                <img src={card.sr} alt={card.name} />
                                <div className='Text-card'>
                                    <h2>{card.name}</h2>
                                    <p>{card.especialidad}</p>
                                    <p>{card.rol}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="Flecha Fderecha" onClick={cardRight}>
                        <img src={Flecha} alt="pasar usuario"/>
                    </div>
                    <div className="Flecha Fizquierda" onClick={cardLeft}>
                        <img src={Flecha} alt="regresar usuario"/>
                    </div>
                </div>
            </div>
            <article className='Team-text-container n2'>
                <p>¡prepárense para un emocionante viaje de aprendizaje, crecimiento y logros! Estamos ansiosos por trabajar juntos, enfrentar desafíos y llevar la tecnología en Fixcenter a un nivel superior. Juntos, somos un equipo poderoso y no puedo esperar para ver lo que podemos lograr juntos. <span>¡Bienvenidos al equipo técnico de Fixcenter!"</span></p>
            </article>
        </section>
    );
}

export {Team};