import React from "react";
import './Main.css';

function Main({children}) {
    return (
        <>
            <main>{children}</main>
        </>
    );
}

export {Main};