import React from "react";
import home from "../Assets/Icons/Home/Home.png";
import team from "../Assets/Icons/Team/Team.png";
import speciality from "../Assets/Icons/Phone/Phone.png";
// import settings from "../Assets/Icons/Settings/Settings.png";
import whatsapp from "../Assets/Icons/Footer/whatsapp.png";
import facebook from "../Assets/Icons/Footer/facebook.png";
import instagram from "../Assets/Icons/Footer/instagram.png";
import tiktok from "../Assets/Icons/Footer/tik-tok.png";
import gerardo from "../Assets/Images/EquipoFixcenter/Gerardo.png";
import luis from "../Assets/Images/EquipoFixcenter/Luis.png";
import ruben from "../Assets/Images/EquipoFixcenter/Rubén.png";
import yefer from "../Assets/Images/EquipoFixcenter/Yefer.png";
import jaime from "../Assets/Images/EquipoFixcenter/Jaime.png";
import marcos from "../Assets/Images/EquipoFixcenter/Marcos.png";
import andres from "../Assets/Images/EquipoFixcenter/Andrés.png";
import estefa from "../Assets/Images/EquipoFixcenter/Estefa.png";
import notFound404 from "../Assets/Svgs/NotFound404.svg";

const AppContext = React.createContext();

const itemsList = [
    {text:'Casa', sr: home, selected: true},
    {text:'Equipo', sr: team, selected: false},
    {text:'Especialidad', sr: speciality, selected: false}//,
    // {text:'Configuraciones', sr: settings, selected: false}
];

const itemsFooter = [
    {text: 'Whatsapp', sr: whatsapp, link: 'https://wa.link/h3ab4h'},
    {text: 'Facebook', sr: facebook, link: 'https://www.facebook.com/fixcenter.repara?mibextid=LQQJ4d'},
    {text: 'Instagram', sr: instagram, link: 'https://instagram.com/fixcenter_repara?igshid=MzRlODBiNWFlZA=='},
    {text: 'Tiktok', sr: tiktok, link: 'https://www.tiktok.com/@fixcenter_repara?_t=8g6J5mzV4YY&_r=1'},
];

const cardList = [
    {
        key: 1,
        name: 'Gerardo Florez', 
        rol: 'Gerente general', 
        especialidad: 'Cambio de batería, Cambio de pantalla, cambio de flexores, swap.',
        sr: gerardo
    },
    {
        key: 2,
        name: 'Luis Torres',
        rol: 'Gerente Tecnico',
        especialidad: 'Microsoldadura, carga, imagen, recuperación de datos, señal, reinicios, diagnostico avanzado, radiofrecuencia en dispositivos Apple.',
        sr: luis
    },
    {
        key: 3,
        name: 'Rubén Guzmán',
        rol: 'Especialista en reparación movil',
        especialidad: '+17 años de experiencia en: recuperación de datos, fallas de señal, carga, imagen, encendida, sonido, ampliación de memoria en dispositivos Apple.',
        sr: ruben
    },
    {
        key: 4,
        name: 'Yeferson Murillo',
        rol: 'Especialista en Apple',
        especialidad: 'Cambio de batería, pantallas, sonido, señal, encedida, carga, imagen, reinicio, WiFi.',
        sr: yefer
    },
    {
        key: 5,
        name: 'Jaime Ruiz',
        rol: 'Especialista en Android y CNC',
        especialidad: 'CNC, reparación de dispositivos Android, carga, imagen, señal, encendida.',
        sr: jaime
    },
    {
        key: 6,
        name: 'Marcos Daniel Arrieta',
        rol: 'Control de calidad',
        especialidad: 'Cambio de flexores, diagnóstico de todo dispositivo móvil.',
        sr: marcos
    },
    {
        key: 7,
        name: 'Andrés Acosta',
        rol: 'Supervisor de ventas de repuestos originales',
        especialidad: 'Especialista en Experiencia del Cliente y Estrategias Comerciales.',
        sr: andres
    },
    {
        key: 8,
        name: 'Estefania García',
        rol: 'Auxiliar administrativa',
        especialidad: 'Atención al cliente, finanzas, creación de contenido.',
        sr: estefa
    }
];

const specialities = [
    {
        proceso: "IC de carga",
        texto: [
            {
                parrafo: 1,
                texto: "El IC de carga, o Circuito Integrado de Carga, es un componente esencial en dispositivos electrónicos que administra y controla la carga de la batería. Este pequeño chip se encarga de regular la corriente y el voltaje que fluyen hacia la batería, evitando sobrecargas y daños a la batería o al dispositivo."
            },
            {
                parrafo: 2,
                texto: "Durante la reparación de un IC de carga, se llevan a cabo diagnósticos para identificar problemas de carga y, en caso necesario, se pueden realizar tareas como la sustitución del IC o la soldadura de componentes. El proceso busca restaurar la funcionalidad adecuada de la carga y garantizar que el dispositivo vuelva a cargarse correctamente."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Flex de carga",
        texto: [
            {
                parrafo: 1,
                texto: "El flex de carga es un cable flexible esencial en dispositivos electrónicos, como teléfonos móviles. Conecta el puerto de carga al circuito de la placa base, permitiendo la transferencia de energía para cargar la batería. Cualquier daño en este cable puede causar problemas de carga o conexiones inestables."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "SWAP",
        texto: [
            {
                parrafo: 1,
                texto: "En tecnología y reparación de dispositivos, SWAP es un término que describe el acto de intercambiar o reemplazar componentes específicos, como baterías, pantallas, módulos, etc., generalmente con el propósito de reparar o mantener un dispositivo. El término SWAP sugiere que se quita un componente defectuoso y se coloca uno funcional en su lugar, a menudo como parte de un proceso de reparación rápida o reemplazo de piezas."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Señal",
        texto: [
            {
                parrafo: 1,
                texto: "El dispositivo de señal en dispositivos móviles se refiere a los componentes que permiten la comunicación inalámbrica, como la conectividad a redes móviles, Wi-Fi, Bluetooth y GPS. Esto incluye módulos y antenas que posibilitan la transmisión y recepción de datos y voz. Estos componentes son esenciales para las funciones de conectividad y comunicación en los dispositivos móviles."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "WiFi",
        texto: [
            {
                parrafo: 1,
                texto: "En dispositivos móviles es el conjunto de componentes de hardware y software responsables de habilitar la conectividad inalámbrica a redes locales Wi-Fi. Este dispositivo incluye un módulo de Wi-Fi que permite que el dispositivo se comunique con enrutadores y puntos de acceso Wi-Fi, lo que facilita la conexión a Internet y otros recursos en una red local. Los dispositivos móviles utilizan esta tecnología para acceder a Internet, descargar aplicaciones, enviar y recibir correos electrónicos, transmitir contenido multimedia y realizar una amplia variedad de tareas en línea sin necesidad de cables."
            },
            {
                parrafo: 2,
                texto: "Además, este módulo de Wi-Fi también maneja aspectos relacionados con la seguridad y la autenticación en redes Wi-Fi protegidas por contraseñas, garantizando que las conexiones sean seguras y privadas. En resumen, el dispositivo de Wi-Fi es esencial para la conectividad inalámbrica de dispositivos móviles, mejorando su funcionalidad y versatilidad al permitirles conectarse a redes Wi-Fi disponibles."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Sonido",
        texto: [
            {
                parrafo: 1,
                texto: "En dispositivos móviles se refiere al conjunto de componentes de hardware y software responsables de la reproducción de audio. Esto incluye altavoces incorporados, auriculares, micrófonos y controladores de audio. Los altavoces permiten que escuchemos música, llamadas telefónicas, videos y otros contenidos multimedia, mientras que los micrófonos nos permiten hablar y grabar sonido."
            },
            {
                parrafo: 2,
                texto: "Los controladores de audio gestionan la calidad del sonido y su reproducción, asegurando que el audio se escuche con la mejor calidad posible en un dispositivo móvil. Estos dispositivos son esenciales para la experiencia de audio en dispositivos móviles y son utilizados en una variedad de aplicaciones, desde música y juegos hasta llamadas telefónicas y asistentes de voz."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Flex sonido",
        texto: [
            {
                parrafo: 1,
                texto: "El flex de sonido en dispositivos móviles se refiere a un componente que conecta el altavoz del dispositivo a la placa base. Este flex, a menudo en forma de un cable flexible, permite la transmisión de señales de audio desde la placa base al altavoz, lo que posibilita la reproducción de sonido."
            },
            {
                parrafo: 2,
                texto: "En resumen, el flex de sonido es esencial para la funcionalidad del altavoz en dispositivos móviles, ya que permite que se escuche el audio, ya sea música, llamadas telefónicas, notificaciones o cualquier otro tipo de sonido emitido por el dispositivo."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Face ID",
        texto: [
            {
                parrafo: 1,
                texto: "Face ID es una tecnología de reconocimiento facial desarrollada por Apple. Se utiliza en dispositivos móviles, como iPhones e iPads, para desbloquear el dispositivo, autenticarse en aplicaciones y realizar pagos móviles mediante el reconocimiento facial del usuario."
            },
            {
                parrafo: 2,
                texto: "Face ID utiliza sensores de infrarrojos y una cámara frontal especial para escanear y crear un mapa tridimensional del rostro del usuario. Luego, compara este mapa con el que se registró previamente para determinar si corresponde al propietario del dispositivo."
            },
            {
                parrafo: 3,
                texto: "Esta tecnología es altamente segura y precisa y ha reemplazado el uso de contraseñas o códigos PIN en dispositivos Apple compatibles. Además de su función principal de desbloqueo facial, Face ID se utiliza para autorizar transacciones y autenticar identidades en diversas aplicaciones y servicios."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Display",
        texto: [
            {
                parrafo: 1,
                texto: "El término display se refiere a la pantalla de visualización en dispositivos electrónicos, como computadoras, televisores, teléfonos móviles y tabletas. El display muestra información visual, que puede ser texto, imágenes, videos o gráficos, para que el usuario pueda interactuar con el dispositivo y recibir información."
            },
            {
                parrafo: 2,
                texto: "Los displays pueden ser de varios tipos, incluyendo pantallas LCD (pantallas de cristal líquido), OLED (diodos orgánicos emisores de luz), LED (diodos emisores de luz) y más. La calidad de un display se mide en términos de resolución, tamaño, brillo, contraste y capacidad táctil en el caso de pantallas táctiles."
            },
            {
                parrafo: 3,
                texto: "En resumen, el display es la parte del dispositivo electrónico que muestra información visual y es fundamental para la experiencia de usuario en la mayoría de los dispositivos modernos."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Cambio de bateria",
        texto: [
            {
                parrafo: 1,
                texto: "Una batería móvil se refiere a una batería externa o banco de energía diseñado para cargar dispositivos móviles, como teléfonos inteligentes, tabletas, reproductores de música y otros dispositivos electrónicos portátiles. Estas baterías móviles proporcionan una fuente de energía adicional que se puede utilizar para cargar los dispositivos cuando no hay acceso a una toma de corriente eléctrica, como cuando estás fuera de casa o en movimiento."
            },
            {
                parrafo: 2,
                texto: "Las baterías móviles suelen tener una capacidad de almacenamiento de energía significativa y se cargan previamente desde una toma de corriente. Luego, puedes llevar la batería móvil contigo y conectarla a tu dispositivo cuando necesitas recargarlo. Esto es especialmente útil en situaciones de viaje, actividades al aire libre o en emergencias cuando la batería de tu dispositivo móvil se agota y no puedes cargarlo de forma convencional."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
    {
        proceso: "Aumento de memoria",
        texto: [
            {
                parrafo: 1,
                texto: "Memoria móvil se refiere al espacio de almacenamiento interno en dispositivos móviles, como teléfonos y tabletas, donde se guardan aplicaciones, fotos, música y otros datos."
            }
        ],
        videos: [
            {key: 1, sr: notFound404}
        ],
        selected: false
    },
]

function ProviderApp({children}) {
    const [openMenu, setOpenMenu] = React.useState(false);
    const [items, setItems] = React.useState(itemsList);
    const [especialidad, setEspecialidad] = React.useState(specialities)
    const [moveWelcome, setMoveWelcome] =  React.useState(false);
    const [num, setNum] = React.useState(0);

    const selectItems = (text) => {
        const newItems = [...items];
        const itemIndex = newItems.findIndex((item) => item.text === text);
        newItems.map((item) => (
            item.selected = false
        ));
        newItems[itemIndex].selected = true;
        setItems(newItems);
    };

    const selectSpeciality = (proceso) => {
        const newEspecialidad = [...especialidad];
        const especialidadIndex = newEspecialidad.findIndex((opcion) => opcion.proceso === proceso);
        newEspecialidad.map((opcion) => (
            opcion.selected = false
        ));
        newEspecialidad[especialidadIndex].selected = true;
        setEspecialidad(newEspecialidad);
    }

    const seleccion = (proceso) => {
        const newEspecialidad = [...especialidad];
        const especialidadIndex = newEspecialidad.findIndex((opcion) => opcion.proceso === proceso);
        if (newEspecialidad[especialidadIndex].selected === false) {
            selectSpeciality(proceso);
        } else {
            newEspecialidad[especialidadIndex].selected = false;
            setEspecialidad(newEspecialidad);
        }
    }

    const boolOpuesto = (value) => {
        value(state => !state);
    };

    const sumar = () => setNum(num + 1);
    const restar = () => setNum(num - 1);

    const pasarCard = () => {
        if (num < 7) {
            sumar();
        } else {
            setNum(0);
        }
    };

    const atrasarCard = () => {
        if (num > 0) {
            restar();
        } else {
            setNum(7);
        }
    }

    return (
        <AppContext.Provider value={{
            openMenu, setOpenMenu, itemsList, selectItems, boolOpuesto, setMoveWelcome, moveWelcome,
            itemsFooter, cardList, pasarCard, atrasarCard, num, specialities, seleccion
        }}>
            {children}
        </AppContext.Provider>
    );
};

export {ProviderApp, AppContext};