import React from 'react';
import { HeaderApp } from '../Components/Header';
import { NavBar } from '../Components/NavBar';
import { NavBarItems } from '../Components/NavBarItems';
import { Main } from '../Components/Main';
import { AppContext } from '../Context/ProviderApp';
import { Home } from '../Components/Home';
import { ComoAyudarButton } from '../Components/ComoAyudarButton';
import { Footer } from '../Components/Footer';
import { Team } from '../Components/Team';
import { Speciality } from '../Components/Speciality';

function AppUI() {
  const {itemsList} = React.useContext(AppContext);

  return (
    <>
      <HeaderApp>
        <NavBar>
          <NavBarItems/>
        </NavBar>
      </HeaderApp>
      <Main>
        {itemsList.map(item => (
          (item.text === "Casa" && item.selected) && (
            <Home key={item.text}></Home>
          )
        ))}
        {itemsList.map(item => (
          (item.text === "Equipo" && item.selected) && (
            <Team key={item.text}></Team>
          )
        ))}
        {itemsList.map(item => (
          (item.text === "Especialidad" && item.selected) && (
            <Speciality key={item.text}></Speciality>
          )
        ))}
      </Main>
      <ComoAyudarButton />
      <Footer/>
    </>
  );
};

export {AppUI};