import React from "react";
import './NavBarItems.css'
import { AppContext } from "../../Context/ProviderApp";

function NavBarItems(){
    const {itemsList, openMenu, selectItems} = React.useContext(AppContext);

    return (
        <>
            {itemsList.map(item => (
                <li className={`Item ${item.selected && "Icon-selected"}`} key={item.text} onClick={() => selectItems(item.text)}>
                    {openMenu && (
                        <img src={item.sr} alt={item.text}/>
                    )}
                    <span className="Item-text">{item.text}</span>
                </li>
            ))}
        </>
    );
};

export {NavBarItems};