import React from "react";
import './Header.css'
import logo from "../../Assets/Logos/FixcenterLogo.png"
import icono from "../../Assets/Icons/Menu/menu-principal.png"
import iconoSeleted from "../../Assets/Icons/Menu/menu-principal-selected.png"
import { AppContext } from "../../Context/ProviderApp";

function HeaderApp({children}) {
    const {setOpenMenu, openMenu, boolOpuesto} = React.useContext(AppContext);
    const openClose = () => boolOpuesto(setOpenMenu);

    return (
        <header>
            <div className="Icons-container">
                <div className="Logo-container">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="Icon-container">
                    {!openMenu && <img src={icono} alt="menu" className="Icon Icon-unselect" onClick=   {openClose}></img>}
                    {openMenu && <img src={iconoSeleted} alt="menu" className="Icon Icon-select" onClick=   {openClose}></img>}
                </div>
            </div>
            <div className={`Nav-container ${!openMenu && "Nav--close"}`}>{children}</div>
        </header>
    );
}

export {HeaderApp};